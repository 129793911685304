<template>
  <div id="neutralElectrodeCard">
    <el-dialog
      :title="neutralElectrodeCardFormTitle"
      width="1500px"
      :visible.sync="neutralElectrodeCardDialogVisible"
      :close-on-click-modal="false"
      @close="neutralElectrodeCardDialogClose"
    >
      <el-form
        ref="neutralElectrodeCardFormRef"
        :model="neutralElectrodeCardForm"
        :rules="neutralElectrodeCardFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="出库单编号" prop="orderOutNo">
              <el-input v-model="neutralElectrodeCardForm.orderOutNo" placeholder="请输入出库单编号">
                <i slot="suffix" class="el-input__icon el-icon-success" @click="selectOrderOut" />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productionName">
              <el-input
                v-model="neutralElectrodeCardForm.productionName"
                readonly
                :disabled="neutralElectrodeCardFormTitle !== '新增(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '修改(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '(带线)一次性使用中性电极生产流程卡详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号规格" prop="spec">
              <el-input
                v-model="neutralElectrodeCardForm.spec"
                placeholder="请输入型号规格"
                clearable
                :disabled="neutralElectrodeCardFormTitle !== '新增(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '修改(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '(带线)一次性使用中性电极生产流程卡详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产批号" prop="productionBatchNo">
              <el-input
                v-model="neutralElectrodeCardForm.productionBatchNo"
                placeholder="请输入生产批号"
                clearable
                :disabled="neutralElectrodeCardFormTitle !== '新增(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '修改(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '(带线)一次性使用中性电极生产流程卡详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划生产时间" prop="plannedTime">
              <el-date-picker
                v-model="neutralElectrodeCardForm.plannedTime"
                placeholder="请选择计划生产时间"
                value-format="yyyy-MM-dd"
                :disabled="neutralElectrodeCardFormTitle !== '新增(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '修改(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '(带线)一次性使用中性电极生产流程卡详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划生产数量" prop="plannedQuantity">
              <el-input
                v-model="neutralElectrodeCardForm.plannedQuantity"
                placeholder="请输入计划生产数量"
                clearable
                :disabled="neutralElectrodeCardFormTitle !== '新增(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '修改(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '(带线)一次性使用中性电极生产流程卡详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="height: 62.8px;">
            <el-form-item label="状态" prop="type">
              <el-radio-group
                v-model="neutralElectrodeCardForm.type"
                :disabled="neutralElectrodeCardFormTitle !== '新增(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '修改(带线)一次性使用中性电极生产流程卡'
                  && neutralElectrodeCardFormTitle !== '(带线)一次性使用中性电极生产流程卡详情'"
              >
                <el-radio :label="1">
                  半成品
                </el-radio>
                <el-radio :label="2">
                  成品
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="原材料使用登记">
          <vxe-toolbar
            v-if="neutralElectrodeCardFormTitle === '新增(带线)一次性使用中性电极生产流程卡'
              || neutralElectrodeCardFormTitle === '修改(带线)一次性使用中性电极生产流程卡'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column field="materialNo" title="物料编号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择物料"
                  clearable
                  filterable
                  @change="materialPurchaseStockChange(row)"
                >
                  <el-option
                    v-for="item in materialPurchaseStockList"
                    :key="item.id"
                    :label="item.materialNo"
                    :value="item.materialNo"
                  >
                    <span style="float: left">{{ item.materialNo }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.productName }}</span>
                  </el-option>
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="materialName"
              title="物料名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="spec"
              title="规格型号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="batchNo"
              title="批号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="quantity"
              title="数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="neutralElectrodeCardFormTitle === '新增(带线)一次性使用中性电极生产流程卡'
                || neutralElectrodeCardFormTitle === '修改(带线)一次性使用中性电极生产流程卡'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-form-item label="组装">
          <vxe-toolbar v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡组装操作'">
            <template #buttons>
              <vxe-button @click="assemblyInsertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="assemblyTable"
            border
            resizable
            show-overflow
            keep-source
            :data="assemblyList"
            :edit-config="{trigger: 'click', mode: 'cell', activeMethod: assemblyActiveRowMethod}"
            show-footer
            :footer-method="footerMethod"
          >
            <vxe-table-column
              field="assemblyDate"
              title="日期"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="assemblyQuantity"
              title="组装数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="qualifiedQuantity"
              title="合格数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="unqualifiedQuantity"
              title="不合格数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="unqualifiedReason"
              title="不合格原因"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="margin"
              title="余量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="operator"
              title="操作人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="operationDate"
              title="日期"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="reviewer"
              title="复核人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="reviewDate"
              title="日期"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡组装操作'
                || neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡组装复核' "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button
                    v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡组装操作'
                      && row.reviewResult === 1"
                    @click="assemblyRemoveRowEvent(row)"
                  >
                    删除
                  </vxe-button>
                  <vxe-button
                    v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡组装复核'
                      && row.reviewResult === 1"
                    @click="assemblyReviewEvent(row)"
                  >
                    复核
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-form-item label="整件检验">
          <vxe-toolbar v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡整件检验操作'">
            <template #buttons>
              <vxe-button @click="inspectionInsertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="inspectionTable"
            border
            resizable
            show-overflow
            keep-source
            :data="inspectionList"
            :edit-config="{trigger: 'click', mode: 'cell', activeMethod: inspectionActiveRowMethod}"
            show-footer
            :footer-method="footerMethod"
          >
            <vxe-table-column
              field="inspectionDate"
              title="日期"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="inspectionQuantity"
              title="数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="qualifiedQuantity"
              title="合格数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="unqualifiedQuantity"
              title="不合格数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="unqualifiedReason"
              title="不合格原因"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="margin"
              title="余量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column field="deviceNo" title="编号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择设备"
                  clearable
                  filterable
                  @change="devChange(row)"
                >
                  <el-option
                    v-for="item in devList"
                    :key="item.id"
                    :label="item.deviceNo"
                    :value="item.deviceNo"
                  >
                    <span style="float: left">{{ item.deviceNo }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                  </el-option>
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="deviceName"
              title="设备名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="parameterRecord"
              title="参数记录"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="operator"
              title="操作人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="operationDate"
              title="日期"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="reviewer"
              title="复核人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="reviewDate"
              title="日期"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡整件检验操作'
                || neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡整件检验复核' "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button
                    v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡整件检验操作'
                      && row.reviewResult === 1"
                    @click="inspectionRemoveRowEvent(row)"
                  >
                    删除
                  </vxe-button>
                  <vxe-button
                    v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡整件检验复核'
                      && row.reviewResult === 1"
                    @click="inspectionReviewEvent(row)"
                  >
                    复核
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-form-item label="黏贴海绵">
          <vxe-toolbar v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡黏贴海绵操作'">
            <template #buttons>
              <vxe-button @click="pasteInsertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="pasteTable"
            border
            resizable
            show-overflow
            keep-source
            :data="pasteList"
            :edit-config="{trigger: 'click', mode: 'cell', activeMethod: pasteActiveRowMethod}"
            show-footer
            :footer-method="footerMethod"
          >
            <vxe-table-column
              field="pasteDate"
              title="日期"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="pasteQuantity"
              title="数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="qualifiedQuantity"
              title="合格数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="unqualifiedQuantity"
              title="不合格数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="unqualifiedReason"
              title="不合格原因"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="margin"
              title="余量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="operator"
              title="操作人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="operationDate"
              title="日期"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="reviewer"
              title="复核人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="reviewDate"
              title="日期"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡黏贴海绵操作'
                || neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡黏贴海绵复核' "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button
                    v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡黏贴海绵操作'
                      && row.reviewResult === 1"
                    @click="pasteRemoveRowEvent(row)"
                  >
                    删除
                  </vxe-button>
                  <vxe-button
                    v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡黏贴海绵复核'
                      && row.reviewResult === 1"
                    @click="pasteReviewEvent(row)"
                  >
                    复核
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-form-item label="封小包装（特殊）">
          <vxe-toolbar v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡封小包装操作'">
            <template #buttons>
              <vxe-button @click="sealInsertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="sealTable"
            border
            resizable
            show-overflow
            keep-source
            :data="sealList"
            :edit-config="{trigger: 'click', mode: 'cell', activeMethod: sealActiveRowMethod}"
            show-footer
            :footer-method="footerMethod"
          >
            <vxe-table-column
              field="sealDate"
              title="日期"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="sealQuantity"
              title="封内包装数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="qualifiedQuantity"
              title="合格数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="unqualifiedQuantity"
              title="不合格数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="unqualifiedReason"
              title="不合格原因"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="margin"
              title="余量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column field="deviceNo" title="编号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择设备"
                  clearable
                  filterable
                  @change="devChange(row)"
                >
                  <el-option
                    v-for="item in devList"
                    :key="item.id"
                    :label="item.deviceNo"
                    :value="item.deviceNo"
                  >
                    <span style="float: left">{{ item.deviceNo }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                  </el-option>
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="deviceName"
              title="设备名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="parameterRecord"
              title="参数记录"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="operator"
              title="操作人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="operationDate"
              title="日期"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="reviewer"
              title="复核人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="reviewDate"
              title="日期"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡封小包装操作'
                || neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡封小包装复核' "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button
                    v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡封小包装操作'
                      && row.reviewResult === 1"
                    @click="sealRemoveRowEvent(row)"
                  >
                    删除
                  </vxe-button>
                  <vxe-button
                    v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡封小包装复核'
                      && row.reviewResult === 1"
                    @click="sealReviewEvent(row)"
                  >
                    复核
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-form-item label="装箱">
          <vxe-toolbar v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡装箱操作'">
            <template #buttons>
              <vxe-button @click="boxInsertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="boxTable"
            border
            resizable
            show-overflow
            keep-source
            :data="boxList"
            :edit-config="{trigger: 'click', mode: 'cell', activeMethod: boxActiveRowMethod}"
            show-footer
            :footer-method="footerMethod"
          >
            <vxe-table-column
              field="boxDate"
              title="日期"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="boxQuantity"
              title="外包数量（箱）"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="qualifiedQuantity"
              title="合格数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="unqualifiedQuantity"
              title="不合格数"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="unqualifiedReason"
              title="不合格原因"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="margin"
              title="余量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="operator"
              title="操作人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="operationDate"
              title="日期"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="reviewer"
              title="复核人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="reviewDate"
              title="日期"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡装箱操作'
                || neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡装箱复核' "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button
                    v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡装箱操作'
                      && row.reviewResult === 1"
                    @click="boxRemoveRowEvent(row)"
                  >
                    删除
                  </vxe-button>
                  <vxe-button
                    v-if="neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡装箱复核'
                      && row.reviewResult === 1"
                    @click="boxReviewEvent(row)"
                  >
                    复核
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="neutralElectrodeCardDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="neutralElectrodeCardFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productionName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['SC_ZXSC_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="neutralElectrodeCardPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productionName" label="产品名称" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="productionBatchNo" label="生产批号" />
      <el-table-column label="计划生产时间">
        <template slot-scope="scope">
          <span v-if="scope.row.plannedTime">{{ scope.row.plannedTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="plannedQuantity" label="计划生产数量" />
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">半成品</span>
          <span v-if="scope.row.type === 2">成品</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['SC_ZXSC_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['SC_ZXSC_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['SC_ZXSC_ZZ_CZ'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleZZCZ(scope.$index, scope.row)"
          >
            组装操作
          </el-button>
          <el-button
            v-if="checkPermission(['SC_ZXSC_ZZ_FH'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleZZFH(scope.$index, scope.row)"
          >
            组装复核
          </el-button>
          <el-button
            v-if="checkPermission(['SC_ZXSC_ZJJY_CZ'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleZJJYCZ(scope.$index, scope.row)"
          >
            整件检验操作
          </el-button>
          <el-button
            v-if="checkPermission(['SC_ZXSC_ZJJY_FH'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleZJJYFH(scope.$index, scope.row)"
          >
            整件检验复核
          </el-button>
          <el-button
            v-if="checkPermission(['SC_ZXSC_NTHM_CZ'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleNTHMCZ(scope.$index, scope.row)"
          >
            黏贴海绵操作
          </el-button>
          <el-button
            v-if="checkPermission(['SC_ZXSC_NTHM_FH'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleNTHMFH(scope.$index, scope.row)"
          >
            黏贴海绵复核
          </el-button>
          <el-button
            v-if="checkPermission(['SC_ZXSC_FXBZ_CZ'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleFXBZCZ(scope.$index, scope.row)"
          >
            封小包装操作
          </el-button>
          <el-button
            v-if="checkPermission(['SC_ZXSC_FXBZ_FH'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleFXBZFH(scope.$index, scope.row)"
          >
            封小包装复核
          </el-button>
          <el-button
            v-if="checkPermission(['SC_ZXSC_ZX_CZ'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleZXCZ(scope.$index, scope.row)"
          >
            装箱操作
          </el-button>
          <el-button
            v-if="checkPermission(['SC_ZXSC_ZX_FH'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleZXFH(scope.$index, scope.row)"
          >
            装箱复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="neutralElectrodeCardPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addNeutralElectrodeCard,
  deleteNeutralElectrodeCard,
  updateNeutralElectrodeCard,
  selectNeutralElectrodeCardInfo,
  selectNeutralElectrodeCardList,
  operateNeutralElectrodeCard,
  reviewNeutralElectrodeCard
} from '@/api/produce/neutralElectrodeCard'
import { selectMaterialPurchaseStockList } from '@/api/storage/materialPurchaseStock'
import { selectOrderOutByNo } from '@/api/storage/orderOut'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      neutralElectrodeCardDialogVisible: false,
      neutralElectrodeCardFormTitle: '',
      neutralElectrodeCardForm: {
        id: '',
        productionName: '(带线)一次性使用中性电极',
        spec: '',
        productionBatchNo: '',
        plannedTime: '',
        plannedQuantity: '',
        type: '',
        code: 1,
        detailJson: '',
        orderOutNo: ''
      },
      neutralElectrodeCardFormRules: {
        productionName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      neutralElectrodeCardPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productionName: '',
        code: 1
      },
      detailList: [],
      materialPurchaseStockList: [],
      assemblyList: [],
      inspectionList: [],
      pasteList: [],
      sealList: [],
      boxList: [],
      devList: []
    }
  },
  created () {
    selectNeutralElectrodeCardList(this.searchForm).then(res => {
      this.neutralElectrodeCardPage = res
    })
    selectMaterialPurchaseStockList({ type: 1 }).then(res => {
      this.materialPurchaseStockList = res.list
    })
    selectBaseInfoList().then(res => {
      this.devList = res.list
    })
  },
  methods: {
    neutralElectrodeCardDialogClose () {
      this.$refs.neutralElectrodeCardFormRef.resetFields()
      this.detailList = []
      this.assemblyList = []
      this.inspectionList = []
      this.pasteList = []
      this.sealList = []
      this.boxList = []
    },
    neutralElectrodeCardFormSubmit () {
      if (this.neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡详情') {
        this.neutralElectrodeCardDialogVisible = false
        return
      }
      this.$refs.neutralElectrodeCardFormRef.validate(async valid => {
        if (valid) {
          if (this.neutralElectrodeCardFormTitle === '新增(带线)一次性使用中性电极生产流程卡') {
            this.neutralElectrodeCardForm.id = ''
            this.neutralElectrodeCardForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addNeutralElectrodeCard(this.neutralElectrodeCardForm)
          } else if (this.neutralElectrodeCardFormTitle === '修改(带线)一次性使用中性电极生产流程卡') {
            this.neutralElectrodeCardForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateNeutralElectrodeCard(this.neutralElectrodeCardForm)
          } else if (this.neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡组装操作') {
            await operateNeutralElectrodeCard({
              id: this.neutralElectrodeCardForm.id,
              type: 1,
              assemblyJson: JSON.stringify(this.$refs.assemblyTable.getTableData().tableData)
            })
          } else if (this.neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡整件检验操作') {
            await operateNeutralElectrodeCard({
              id: this.neutralElectrodeCardForm.id,
              type: 2,
              inspectionJson: JSON.stringify(this.$refs.inspectionTable.getTableData().tableData)
            })
          } else if (this.neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡黏贴海绵操作') {
            await operateNeutralElectrodeCard({
              id: this.neutralElectrodeCardForm.id,
              type: 3,
              pasteJson: JSON.stringify(this.$refs.pasteTable.getTableData().tableData)
            })
          } else if (this.neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡封小包装操作') {
            await operateNeutralElectrodeCard({
              id: this.neutralElectrodeCardForm.id,
              type: 4,
              sealJson: JSON.stringify(this.$refs.sealTable.getTableData().tableData)
            })
          } else if (this.neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡装箱操作') {
            await operateNeutralElectrodeCard({
              id: this.neutralElectrodeCardForm.id,
              type: 5,
              boxJson: JSON.stringify(this.$refs.boxTable.getTableData().tableData)
            })
          }
          this.neutralElectrodeCardPage = await selectNeutralElectrodeCardList(this.searchForm)
          this.neutralElectrodeCardDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.neutralElectrodeCardFormTitle = '新增(带线)一次性使用中性电极生产流程卡'
      this.neutralElectrodeCardDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteNeutralElectrodeCard(row.id)
        if (this.neutralElectrodeCardPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.neutralElectrodeCardPage = await selectNeutralElectrodeCardList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.neutralElectrodeCardFormTitle = '修改(带线)一次性使用中性电极生产流程卡'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    handleZZCZ (index, row) {
      this.neutralElectrodeCardFormTitle = '(带线)一次性使用中性电极生产流程卡组装操作'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    handleZZFH (index, row) {
      this.neutralElectrodeCardFormTitle = '(带线)一次性使用中性电极生产流程卡组装复核'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    handleZJJYCZ (index, row) {
      this.neutralElectrodeCardFormTitle = '(带线)一次性使用中性电极生产流程卡整件检验操作'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    handleZJJYFH (index, row) {
      this.neutralElectrodeCardFormTitle = '(带线)一次性使用中性电极生产流程卡整件检验复核'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    handleNTHMCZ (index, row) {
      this.neutralElectrodeCardFormTitle = '(带线)一次性使用中性电极生产流程卡黏贴海绵操作'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    handleNTHMFH (index, row) {
      this.neutralElectrodeCardFormTitle = '(带线)一次性使用中性电极生产流程卡黏贴海绵复核'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    handleFXBZCZ (index, row) {
      this.neutralElectrodeCardFormTitle = '(带线)一次性使用中性电极生产流程卡封小包装操作'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    handleFXBZFH (index, row) {
      this.neutralElectrodeCardFormTitle = '(带线)一次性使用中性电极生产流程卡封小包装复核'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    handleZXCZ (index, row) {
      this.neutralElectrodeCardFormTitle = '(带线)一次性使用中性电极生产流程卡装箱操作'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    handleZXFH (index, row) {
      this.neutralElectrodeCardFormTitle = '(带线)一次性使用中性电极生产流程卡装箱复核'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    handleInfo (index, row) {
      this.neutralElectrodeCardFormTitle = '(带线)一次性使用中性电极生产流程卡详情'
      this.neutralElectrodeCardDialogVisible = true
      this.selectNeutralElectrodeCardInfoById(row.id)
    },
    selectNeutralElectrodeCardInfoById (id) {
      selectNeutralElectrodeCardInfo(id).then(res => {
        this.neutralElectrodeCardForm.id = res.id
        this.neutralElectrodeCardForm.productionName = res.productionName
        this.neutralElectrodeCardForm.spec = res.spec
        this.neutralElectrodeCardForm.productionBatchNo = res.productionBatchNo
        this.neutralElectrodeCardForm.plannedTime = res.plannedTime
        this.neutralElectrodeCardForm.plannedQuantity = res.plannedQuantity
        this.neutralElectrodeCardForm.type = res.type
        this.detailList = res.detailList
        this.assemblyList = res.assemblyList
        this.inspectionList = res.inspectionList
        this.pasteList = res.pasteList
        this.sealList = res.sealList
        this.boxList = res.boxList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectNeutralElectrodeCardList(this.searchForm).then(res => {
        this.neutralElectrodeCardPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectNeutralElectrodeCardList(this.searchForm).then(res => {
        this.neutralElectrodeCardPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectNeutralElectrodeCardList(this.searchForm).then(res => {
        this.neutralElectrodeCardPage = res
      })
    },
    materialPurchaseStockChange (row) {
      if (row.materialNo) {
        let materialPurchaseStock = this.materialPurchaseStockList.find(item => item.materialNo === row.materialNo)
        row.materialName = materialPurchaseStock.productName
        row.spec = materialPurchaseStock.spec
      } else {
        row.materialName = ''
        row.spec = ''
      }
    },
    selectOrderOut () {
      if (this.neutralElectrodeCardForm.orderOutNo) {
        this.detailList = []
        selectOrderOutByNo(this.neutralElectrodeCardForm.orderOutNo).then(res => {
          this.detailList = res.detailList
        })
      }
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    async assemblyInsertRowEvent (row) {
      let { row: newRow } = await this.$refs.assemblyTable.insertAt({ reviewResult: 1 }, -1)
      await this.$refs.assemblyTable.setActiveRow(newRow)
    },
    assemblyRemoveRowEvent (row) {
      this.$refs.assemblyTable.remove(row)
    },
    assemblyReviewEvent (row) {
      reviewNeutralElectrodeCard({ id: row.id, type: 1 }).then(res => {
        this.selectNeutralElectrodeCardInfoById(this.neutralElectrodeCardForm.id)
      })
    },
    assemblyActiveRowMethod ({ row, rowIndex }) {
      if (this.neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡组装操作') {
        return row.reviewResult === 1
      }
      return false
    },
    async inspectionInsertRowEvent (row) {
      let { row: newRow } = await this.$refs.inspectionTable.insertAt({ reviewResult: 1 }, -1)
      await this.$refs.inspectionTable.setActiveRow(newRow)
    },
    inspectionRemoveRowEvent (row) {
      this.$refs.inspectionTable.remove(row)
    },
    inspectionReviewEvent (row) {
      reviewNeutralElectrodeCard({ id: row.id, type: 2 }).then(res => {
        this.selectNeutralElectrodeCardInfoById(this.neutralElectrodeCardForm.id)
      })
    },
    inspectionActiveRowMethod ({ row, rowIndex }) {
      if (this.neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡整件检验操作') {
        return row.reviewResult === 1
      }
      return false
    },
    async pasteInsertRowEvent (row) {
      let { row: newRow } = await this.$refs.pasteTable.insertAt({ reviewResult: 1 }, -1)
      await this.$refs.pasteTable.setActiveRow(newRow)
    },
    pasteRemoveRowEvent (row) {
      this.$refs.pasteTable.remove(row)
    },
    pasteReviewEvent (row) {
      reviewNeutralElectrodeCard({ id: row.id, type: 3 }).then(res => {
        this.selectNeutralElectrodeCardInfoById(this.neutralElectrodeCardForm.id)
      })
    },
    pasteActiveRowMethod ({ row, rowIndex }) {
      if (this.neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡黏贴海绵操作') {
        return row.reviewResult === 1
      }
      return false
    },
    async sealInsertRowEvent (row) {
      let { row: newRow } = await this.$refs.sealTable.insertAt({ reviewResult: 1 }, -1)
      await this.$refs.sealTable.setActiveRow(newRow)
    },
    sealRemoveRowEvent (row) {
      this.$refs.sealTable.remove(row)
    },
    sealReviewEvent (row) {
      reviewNeutralElectrodeCard({ id: row.id, type: 4 }).then(res => {
        this.selectNeutralElectrodeCardInfoById(this.neutralElectrodeCardForm.id)
      })
    },
    sealActiveRowMethod ({ row, rowIndex }) {
      if (this.neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡封小包装操作') {
        return row.reviewResult === 1
      }
      return false
    },
    async boxInsertRowEvent (row) {
      let { row: newRow } = await this.$refs.boxTable.insertAt({ reviewResult: 1 }, -1)
      await this.$refs.boxTable.setActiveRow(newRow)
    },
    boxRemoveRowEvent (row) {
      this.$refs.boxTable.remove(row)
    },
    boxReviewEvent (row) {
      reviewNeutralElectrodeCard({ id: row.id, type: 5 }).then(res => {
        this.selectNeutralElectrodeCardInfoById(this.neutralElectrodeCardForm.id)
      })
    },
    boxActiveRowMethod ({ row, rowIndex }) {
      if (this.neutralElectrodeCardFormTitle === '(带线)一次性使用中性电极生产流程卡装箱操作') {
        return row.reviewResult === 1
      }
      return false
    },
    devChange (row) {
      if (row.deviceNo) {
        let dev = this.devList.find(item => item.deviceNo === row.deviceNo)
        row.deviceName = dev.deviceName
      } else {
        row.deviceName = ''
      }
    },
    footerMethod ({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '总数'
          }
          if (columnIndex === 2) {
            let sum = 0
            data.forEach(row => {
              sum += isNaN(parseInt(row.qualifiedQuantity)) ? 0 : parseInt(row.qualifiedQuantity)
            })
            return sum
          }
          if (columnIndex === 3) {
            let sum = 0
            data.forEach(row => {
              sum += isNaN(parseInt(row.unqualifiedQuantity)) ? 0 : parseInt(row.unqualifiedQuantity)
            })
            return sum
          }
          return ''
        })
      ]
    }
  }
}
</script>

<style>
</style>
